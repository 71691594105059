/* Shows the few steps needed for using the add-on */
import React from "react";
import stepOne from "../images/step1.gif";
import stepTwo from "../images/step2.gif";
import stepThree from "../images/step3.gif";
import { About } from "./About";

export class Steps extends React.Component {
  render() {
    return (
      <>
        <div className="py-4 px-4">
          <About />
        </div>
        <div className="flex flex-col max-w-prose justify-evenly mx-auto">
          <h1 className="text-4xl text-center my-4 font-roboto">
            Get started in just a few steps
          </h1>
          {/* Step 1 */}
          <div className="flex flex-col mb-5">
            <div className="flex flex-row items-center my-3">
              <h1 className="text-xl text-center text-white bg-violet-500 rounded-2xl px-3 py-1 mx-2">
                1
              </h1>
              <h1 className="text-xl text-center">
                Open an event on your calendar
              </h1>
            </div>
            <img
              className="h-5/6 mx-4 lg:mx-0"
              src={stepOne}
              alt="Animation showing a user selecting an event on their Google Calendar"
            ></img>
          </div>
          {/* Step 2 */}
          <div className="flex flex-col mb-5">
            <div className="flex flex-row items-center my-3">
              <h1 className="text-xl text-center text-white bg-violet-500 rounded-2xl px-3 py-1 mx-2">
                2
              </h1>
              <h1 className="text-xl text-center">
                Specify the details of your stream
              </h1>
            </div>
            <img
              className="h-5/6 mx-4 lg:mx-0"
              src={stepTwo}
              alt="Animation showing a user filling out a form about their upcoming stream"
            ></img>
          </div>
          {/* Step 3 */}
          <div className="flex flex-col mb-5">
            <div className="flex flex-row items-center my-3">
              <h1 className="text-xl text-center text-white bg-violet-500 rounded-2xl px-3 py-1 mx-2">
                3
              </h1>
              <h1 className="text-xl text-center">
                Add it to your Twitch schedule
              </h1>
            </div>
            <img
              className="h-5/6 mx-4 lg:mx-0"
              src={stepThree}
              alt="Animation showing a user submitting a form and adding stream to their Twitch schedule with T-Cal"
            ></img>
          </div>
        </div>
        {/* Button - CTA */}
        <div className="flex flex-row justify-center">
          <a href="https://workspace.google.com/marketplace/app/tcal_stream_scheduler/1003946245375">
            <button className="bg-violet-500 text-white text-2xl rounded-xl px-3 py-1 my-4 md:hover:brightness-125">
              Get Started
            </button>
          </a>
        </div>
      </>
    );
  }
}
