import React from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";

interface HeaderProps {
  content: string;
}

function MainHeader(props: HeaderProps) {
  return <h1 className="text-3xl my-3">{props.content}</h1>;
}

function SecondaryHeader(props: HeaderProps) {
  return <h2 className="text-2xl my-3">{props.content}</h2>;
}

function TertiaryHeader(props: HeaderProps) {
  return <h3 className="text-xl underline my-3">{props.content}</h3>;
}

interface ListElementProps {
  boldStartWord?: string;
  content: string;
}

function DefinitionListElement(props: ListElementProps) {
  return (
    <li className="my-2">
      {props.boldStartWord && (
        <span className="font-bold">{props.boldStartWord}</span>
      )}{" "}
      {props.content}
    </li>
  );
}

function ListElement(props: ListElementProps) {
  return <li className="my-2">{props.content}</li>;
}

export class PrivacyPolicy extends React.Component {
  render() {
    return (
      <>
        <div className="min-h-screen">
          <Navbar></Navbar>
          <div className="container mx-auto max-w-prose lg:max-w-5xl mb-24 px-5">
            {/* Privacy Policy Header */}
            <MainHeader content="Privacy Policy" />
            <h3>
              <span className="font-bold">Last updated:</span> December 27, 2022
            </h3>
            <p>
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
              <br />
              <br />
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy. This Privacy
              Policy has been created with the help of the{" "}
              <a
                className="text-blue-500 lg:hover:underline"
                href="https://www.termsfeed.com/privacy-policy-generator/"
              >
                TermsFeed Privacy Policy Generator
              </a>
              .
            </p>
            <MainHeader content="Interpretation and Definitions" />
            <SecondaryHeader content="Interpretation" />
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            {/* Privacy Policy Definition List */}
            <SecondaryHeader content="Definitions" />
            <p>For the purposes of this Privacy Policy:</p>
            <ul className="list-disc ml-6 my-2">
              <DefinitionListElement
                boldStartWord="Application"
                content="refers to T-Cal,
                the software program provided by the Company."
              />
              <DefinitionListElement
                boldStartWord="Company"
                content=' (referred to as
                either "the Company", "We", "Us" or "Our" in this Agreement)
                refers to T-Cal.'
              />
              <DefinitionListElement
                boldStartWord="Country"
                content="refers to: California, United States"
              />
              <DefinitionListElement
                boldStartWord="Device"
                content="means any device that can access the Service, such as a computer, a cellphone or a digital tablet."
              />
              <DefinitionListElement
                boldStartWord="Personal Data"
                content="is any information that relates to an identified or identifiable individual."
              />
              <DefinitionListElement
                boldStartWord="Service"
                content="refers to the Application."
              />
              <DefinitionListElement
                boldStartWord="Service Provider"
                content="means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used."
              />
              <DefinitionListElement
                boldStartWord="Usage Data"
                content="refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit)."
              />
              <DefinitionListElement
                boldStartWord="You"
                content="means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable."
              />
            </ul>
            <MainHeader content="Collecting and Using Your Personal Data" />
            <SecondaryHeader content="Types of Data Collected" />
            <TertiaryHeader content="Personal Data" />
            <p>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You.
              <br />
              <br />
              Personally identifiable information may include, but is not
              limited to:
            </p>
            <ul className="list-disc ml-8">
              <li className="my-2">Usage Data</li>
              <li className="my-2">Information From Other Sources</li>
            </ul>
            <TertiaryHeader content="Usage Data" />
            <p>
              Usage Data is collected automatically when using the Service.
              <br />
              <br />
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
              <br />
              <br />
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
              <br />
              <br />
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
            <TertiaryHeader content="Information From Other Sources" />
            <p>
              We may obtain additional information from other sources to provide
              service properly. We will only access information that You have
              authorized. For example, we need to connect with Twitch Services
              and Google Calendar to synchronize your calendar events.
            </p>
            <SecondaryHeader content="Use of Your Personal Data" />
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul className="list-disc ml-6 my-4">
              <ListElement
                content="To provide and maintain our Service, including monitoring the
                usage of our Service."
              />
              <ListElement
                content="To manage Your Account: to manage Your registration as a user of
                the Service. The Personal Data You provide can give You access
                to different functionalities of the Service that are available
                to You as a registered user."
              />
              <ListElement
                content="To manage Your requests: To attend and manage Your requests to
                Us"
              />
            </ul>
            <p>
              We may share Your personal information in the following
              situations:
            </p>
            <ul className="list-disc ml-6 my-4">
              <ListElement
                content="With Service Providers: We may share Your personal information
                with Service Providers to monitor and analyze the use of our
                Service, to contact You."
              />
              <ListElement
                content="With Your consent: We may disclose Your personal information for
                any other purpose with Your consent."
              />
            </ul>
            <SecondaryHeader content="Retention of Your Personal Data" />
            <p>
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
              <br />
              <br />
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>
            <SecondaryHeader content="Transfer of Your Personal Data" />
            <p>
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
              <br />
              <br />
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
              <br />
              <br />
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>
            <SecondaryHeader content="Delete Your Personal Data" />
            <p>
              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
              <br />
              <br />
              Our Service may give You the ability to delete certain information
              about You from within the Service.
              <br />
              <br />
              You may also contact Us to request access to, correct, or delete
              any personal information that You have provided to Us.
              <br />
              <br />
              Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </p>
            <SecondaryHeader content="Disclosure of Your Personal Data" />
            <TertiaryHeader content="Law enforcement" />
            <p>
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
            <TertiaryHeader content="Other legal requirements" />
            <p>
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </p>
            <ul className="list-disc ml-6 my-2">
              <ListElement content="Comply with a legal obligation" />

              <ListElement content="Protect and defend the rights or property of the Company" />

              <ListElement content="Prevent or investigate possible wrongdoing in connection with the Service" />

              <ListElement content="Protect the personal safety of Users of the Service or the public" />

              <ListElement content="Protect against legal liability" />
            </ul>
            <SecondaryHeader content="Security of Your Personal Data" />
            <p>
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
            <MainHeader content="Children's Privacy" />
            <p>
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
              <br />
              <br />
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.
            </p>
            <MainHeader content="Links to Other Websites" />
            <p>
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
              <br />
              <br />
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <MainHeader content="Changes to this Privacy Policy" />
            <p>
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
              <br />
              <br />
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
              <br />
              <br />
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <MainHeader content="Contact Us" />
            <p>
              If you have any questions about this Privacy Policy, You can
              contact us:
              <ul className="list-disc ml-6 my-2">
                <li>
                  By email:{" "}
                  <a
                    className="text-blue-500 md:hover:underline"
                    href="mail&#116;&#111;&#58;suppo%72%74&#64;tcal&#46;m&#111;%6B%69&#46;co&#37;&#54;&#52;%6&#53;&#115;"
                  >
                    support&#64;tc&#97;&#108;&#46;&#109;oki&#46;&#99;od&#101;s
                  </a>
                </li>
              </ul>
            </p>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
