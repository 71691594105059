import React from "react";
import { Link } from "react-router-dom";
import { Disclaimer } from "../components/Disclaimer";
import { Footer } from "../components/Footer";
import { Hero } from "../components/Hero";
import { Steps } from "../components/Steps";
import { Support } from "../components/Support";

export class Home extends React.Component {
  componentDidMount(): void {
    const idsToAnimate = ["heroHeader", "heroImage"];
    idsToAnimate.forEach((id) => {
      const element = document.getElementById(id);
      element?.classList.remove("hidden");
      element?.classList.add("animate__animated", "animate__fadeIn", "flex");
    });
  }

  render() {
    return (
      <>
        <div className="min-h-screen">
          <div className="bg-violet-500">
            <div className="flex justify-end">
              <Link
                to="/help"
                className="text-white md:hover:underline px-3 mt-2"
              >
                Help
              </Link>
            </div>
            <Hero />
          </div>
          <div className="container mx-auto mt-3 mb-6">
            <Steps />
            <Disclaimer />
            <Support />
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
