import React from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import installPicture from "../images/installFAQ.png";
import examplePicture from "../images/example.png";

export class Help extends React.Component {
  render() {
    return (
      <>
        <div className="min-h-screen">
          <Navbar></Navbar>
          <h1 className="text-3xl my-7 text-center">
            {" "}
            Frequently Asked Questions{" "}
          </h1>
          <div className="container mx-auto max-w-prose lg:max-w-5xl mb-24 px-5">
            <h2 className="text-xl py-2">
              1. I don't see the add-on on my calendar. What do I do?
            </h2>
            <p className="py-2">
              Double check that you have properly installed the add-on by{" "}
              <a
                className="md:hover:underline text-blue-500"
                href="https://workspace.google.com/marketplace/app/tcal_stream_scheduler/1003946245375"
              >
                going here
              </a>{" "}
              and clicking the Install button.
            </p>
            <img
              className="max-w-full mx-auto"
              src={installPicture}
              alt="Highlighting the install button on the Workspace install page"
            ></img>
            <h2 className="text-xl py-2">
              2. I have the add-on installed, but I don't see anywhere to add my
              stream.
            </h2>
            <p className="px-3">
              Make sure you have opened the add-on via the sidebar on the right
              side of your screen. You must first{" "}
              <strong>create and save an event on your calendar</strong>, then
              click on it. <br></br>
              <br></br> Nothing will appear if you are still in the process of
              creating an event.
            </p>
            <img
              className="max-w-full mx-auto"
              src={examplePicture}
              alt="Highlighting the install button on the Workspace install page"
            ></img>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
