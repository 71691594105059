import React from "react";
import { Link } from "react-router-dom";

export class Footer extends React.Component {
  render() {
    return (
      <div className="bg-violet-500 flex flex-col md:flex-row justify-evenly">
        <a href="https://www.twitch.tv/mokired">
          <h1 className="text-white text-center py-2 md:hover:underline">
            Created by Moki Red
          </h1>
        </a>
        <Link to="/terms">
          <h1 className="text-white text-center py-2 md:hover:underline ">
            Terms and Conditions
          </h1>
        </Link>
        <Link to="/privacy">
          <h1 className="text-white text-center py-2 md:hover:underline ">
            Privacy Policy
          </h1>
        </Link>
      </div>
    );
  }
}
