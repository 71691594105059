export function About() {
  return (
    <p className="text-center my-2 text-2xl">
      T-Cal is an add-on that allows you to update your Twitch schedule directly
      from Google Calendar™.
      <br />
      <br />
      Keep your life organized while staying committed to your stream schedule
      by integrating this into your workflow.
    </p>
  );
}
