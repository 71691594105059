import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/tcalLogo.png";

export class Navbar extends React.Component {
  render() {
    return (
      <div className="bg-violet-500">
        <nav className="flex flex-row items-center justify-between px-7 text-white py-1">
          <Link to="/">
            <div className="flex flex-row items-center">
              <img src={logo} alt="T-Cal Logo" className="w-10"></img>
              <span className="px-3">T-Cal</span>
            </div>
          </Link>
          <div className="flex flex-row">
            <Link to="/" className="md:hover:underline px-1">
              Home
            </Link>
            <Link to="/help" className="md:hover:underline px-1">
              Help
            </Link>
          </div>
        </nav>
      </div>
    );
  }
}
