import React from "react";
import logo from "../images/tcalLogo.png";

export class Hero extends React.Component {
  render() {
    return (
      <div className="container mx-auto flex flex-col justify-evenly md:flex-row py-5 font-roboto">
        <div id="heroHeader" className="hidden flex-col justify-center">
          <h1 className="text-white text-6xl text-center">T-Cal</h1>
          <h1 className="text-white text-center">
            Your stream scheduling companion
          </h1>
        </div>
        <div id="heroImage" className="hidden justify-center">
          <img src={logo} className="w-1/3" alt="T-Cal Logo" />
        </div>
      </div>
    );
  }
}
