export function Support() {
  return (
    <>
      <h3 className="text-center py-4">
        Any questions or concerns can be sent to{" "}
        <a
          className="text-blue-500 md:hover:underline"
          href="mail&#116;&#111;&#58;suppo%72%74&#64;tcal&#46;m&#111;%6B%69&#46;co&#37;&#54;&#52;%6&#53;&#115;"
        >
          support&#64;tc&#97;&#108;&#46;&#109;oki&#46;&#99;od&#101;s
        </a>
      </h3>
    </>
  );
}
